var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "main-menu",
      on: {
        click: function($event) {
          _vm.$parent.open_menu = false
        }
      }
    },
    [
      _c("router-link", { staticClass: "link", attrs: { to: "/objects/" } }, [
        _vm._v("Новостройки")
      ]),
      _c("router-link", { staticClass: "link", attrs: { to: "/profile" } }, [
        _vm._v("Профиль")
      ]),
      _vm.$store.state.user.admin
        ? _c("router-link", { staticClass: "link", attrs: { to: "/users" } }, [
            _vm._v("Пользователи")
          ])
        : _vm._e(),
      _c("div", { staticClass: "link", on: { click: _vm.signOut } }, [
        _vm._v("Выход")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }