<template lang="pug">
    .main-menu(@click="$parent.open_menu = false")
        router-link.link(to="/objects/") Новостройки
        router-link.link(to="/profile") Профиль
        router-link.link(v-if="$store.state.user.admin" to="/users") Пользователи
        .link(@click="signOut") Выход
</template>

<script>
    export default {
        name: "main_menu",
        mounted() {
            if (window.callibriInit) window.callibriInit()
        },
        methods: {
          signOut() {
            if (confirm('Выйти?')) {
              this.$store.dispatch('SIGN_OUT')
            }
          }
        }
    }
</script>

<style lang="stylus" src="../../../stylus/cmp/main-menu.styl"></style>
