import Vue from "vue/dist/vue.runtime.esm";
import VueRouter from "vue-router/dist/vue-router.esm";
import App from "./views/App.vue"
import Vuex from "vuex/dist/vuex.esm";

Vue.use(VueRouter);
Vue.use(Vuex);

const router = new VueRouter({
    mode: "history",
    routes: require("./router"),
    pathToRegexOptions: {strict: true},
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            // savedPosition is only available for popstate navigations.
            return savedPosition
        } else {
            const position = {x: 0, y: 0};

            // scroll to anchor by returning the selector
            if (to.hash) {
                position.selector = to.hash;

                // specify offset of the element
                if (to.hash === '#anchor2') position.offset = { y: 100 };
                if (document.querySelector(to.hash)) return position;

                // if the returned position is falsy or an empty object,
                // will retain current scroll position.
                return false
            }

            return new Promise(resolve => {
                // check if any matched route config has meta that requires scrolling to top
                if (to.matched.some(m => m.meta.scrollToTop)) {
                    // coords will be used if no selector is provided,
                    // or if the selector didn't match any element.
                    position.x = 0;
                    position.y = 0
                }

                resolve(position)
            })
        }
    }
});

const store = new Vuex.Store(require("./store"));

const app = new Vue({
    el: "#content",
    render: h => h(App),
    router,
    store
});
