const Main = () => import(/* webpackChunkName: "main-page-search-object" */ "./views/main.vue");
const Route = () => import(/* webpackChunkName: "main-page-search-object" */"./views/Route.vue");
const Profile = () => import(/* webpackChunkName: "profile" */"./views/Profile.vue");
const Users = () => import(/* webpackChunkName: "users" */"./views/Users.vue");
const User = () => import(/* webpackChunkName: "user" */"./views/User.vue");

module.exports = [
    {path: "/", name: "Main", component: Main},
    {path: "/profile", name: "Profile", component: Profile},
    {path: "/users", name: "Users", component: Users},
    {path: "/users/:id", name: "User", component: User},
    {path: "/*/", name: "Search", component: Route},
];
