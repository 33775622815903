function serialize (form) {
    let data = [],
        elements = form.querySelectorAll("input, textarea, select");

    for (let element of elements) {
        let newVal;
        switch (element.tagName.toLowerCase()) {
            case "select":
            case "textarea":
                newVal = element.value;
                break;
            default:
                switch (element.type.toLowerCase()) {
                    case "checkbox":
                    case "radio":
                        if (element.checked) newVal = element.value;
                        break;
                    default:
                        newVal = element.value;
                }
        }
        if (newVal && newVal.length > 0) data.push(`${element.name}=${encodeURIComponent(newVal)}`);
    }
    return data.join("&");
}

export function parse_query(search_data) {
    let query = [];

    for (let name in search_data) {
        if (search_data.hasOwnProperty(name) && search_data[name]) {
            if (Array.isArray(search_data[name])) {
                for (let value of search_data[name]) {
                    query.push(`${name}=${value}`)
                }
            } else {
                query.push(`${name}=${search_data[name]}`)
            }
        }
    }
    return query.join("&")
}

export default function submit (form, url="https://mirndv.ru/send_mail") {
    let request = new XMLHttpRequest();
    request.open("POST", url, true);
    request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    request.send(serialize(form));
}

export function clear(data) {
    const null_values = ["до", 0];
    let result = {};
    for (let name in data) {
        if (data.hasOwnProperty(name)) {
            if (Array.isArray(data[name])) {
                result[name] = [];
                for (let value of data[name]) {
                    if (null_values.indexOf(value)) result[name].push(value)
                }
                if (!result[name].length) delete result[name]
            } else {
                if (null_values.indexOf(data[name]) < 0) result[name] = data[name]
            }
        }
    }
    return result;
}
