import axios from "axios";
import {parse_query, clear} from "./fnc/submit"

const API_URL = "https://mirndv.ru";
const PDF_URL = "https://agent-api.mirndv.ru";

const LAYOUT_OBJECT_CACHE = {};

module.exports = {
    strict: process.env.NODE_ENV !== 'production',
    state: {
        auth: null,
        user: {
            id: '',
            admin: false,
            email: '',
            person: {
                firstName: '',
                lastName: '',
                patronymic: '',
                phone: '',
                email: '',
            }
        },
        page_not_found: false,
        selection_title: null,
        search_query: {
            public: true,
            rooms: [],
            cost: 0,
            squire_all_from: 0,
            squire_all_to: 0,
            year: [],
            beside_metro: [],
            remont: [],
            has_discount: [],
            realtytypes: [],
            appart: [],
            has_military: [],
            has_maternity: [],
            no_first: [],
            no_last: [],
            metro: [],
            area: [],
            "time-to": [],
            balcony: [],
            is_new: [],
            has_subsidy: [],
            building_types: [],
            squire_living_from: 0,
            squire_living_to: 0,
            squire_kitchen_from: 0,
            squire_kitchen_to: 0,
            floor_from: 0,
            floor_to: 0,
            metro_dist: 0,
            builder: [],
            in_progress: [],
            "0_room_from": [],
            "0_room_to": [],
            "1_room_from": [],
            "1_room_to": [],
            "2_room_from": [],
            "2_room_to": [],
            "3_room_from": [],
            "3_room_to": [],
            "4_room_from": [],
            "4_room_to": [],
            "5_room_from": [],
            "5_room_to": [],
            "6_room_from": [],
            "6_room_to": [],
            "7_room_from": [],
            "7_room_to": [],
            keywords: [],
            has_rassrochka: [],
            has_mortgage: [],
            bestseller: [],
            class_type: [],
        },
        limit: {
            objects: 10,
            flats: 10
        },
        offset: {
            objects: 0,
            flats: 1
        },
        sort: {
            order: "",
            sort: ""
        },
        list: {
            objects: [],
            flats: []
        },
        count: {
            objects: 0,
            flats: 0
        },
        open_search: false
    },
    actions: {
        GET_OBJECT_PAGE: ({state}, path) => new Promise((resolve, reject) => {
            let path_components = path.split("/");
            if (path_components.length === 4 && path_components[1] === "objects") {
                return axios.get(`${API_URL}/objects/${path_components[2]}/?json=1&without_plans=1&forcecache=1`)
                    .then(res => resolve(res.data))
                    .catch(err => reject(err))
            }
            reject()
        }),
        GET_SEARCH_MAP: ({state}) => {
            return new Promise(resolve => {
                return axios.get(`${API_URL}/map.json?current_query=${JSON.stringify(clear(state.search_query))}&forcecache=1`)
                    .then(res => {
                        resolve(res.data.list)
                    })
            })
        },
        GET_OBJECTS_LIST: ({state, commit}, path) => {
            return new Promise((resolve, reject) => {
                commit("SET_SELECTION_TITLE", null);
                if (path === "/objects-search/") {
                    return axios.post(`${API_URL}/objects-search/?json=1`, `${parse_query(state.search_query)}&limit=${state.limit.objects}&offset=${state.offset.objects}&order=${state.sort.order}&sort=${state.sort.sort}`).then(res => {
                        if (res.data.redirect) {
                            resolve(res.data.redirect.replace("https://mirndv.ru", "").replace("//", "/"));
                        } else {
                            if (res.data.complexes) {
                                commit("SET_COUNT", res.data.count || res.data.selection.count);
                                for (let object of res.data.complexes) {
                                    commit("PUSH_OBJECTS_LIST", object)
                                }
                            } else {
                                commit("NULL_OBJECTS_LIST");
                                reject()
                            }
                            resolve()
                        }
                    })
                } else {
                    axios.get(`${API_URL}${path}?json=1&limit=${state.limit.objects}&offset=${state.offset.objects}&order=${state.sort.order}&sort=${state.sort.sort}&forcecache=1`)
                        .then(res => {
                            /** @namespace object.subways **/
                            /** @namespace object.main_photo **/
                            /** @namespace object.district **/
                            /** @namespace object.plans_count **/
                            /** @namespace object.min_price_field **/
                            /** @namespace object.hasbalcony **/
                            /** @namespace object.area_corridor **/
                            /** @namespace object.area_living **/
                            /** @namespace object.quart **/
                            /** @namespace object.object_name **/
                            if (res.data.selection) {
                                document.title = res.data.selection.title;
                                commit("SET_SELECTION_TITLE", res.data.selection.seo_h1)
                            }
                            if (res.data.complexes) {
                                commit("SET_COUNT", res.data.count || res.data.selection.count);
                                for (let object of res.data.complexes) {
                                    commit("PUSH_OBJECTS_LIST", object)
                                }
                                if (res.data.selection && res.data.selection.query) commit("SET_SEARCH_QUERY", res.data.selection.query);
                            } else {
                                commit("NULL_OBJECTS_LIST");
                                reject()
                            }
                            resolve()
                        })
                        .catch(err => reject(err))
                }
            })
        },
        GET_FLATS_LIST: ({state, commit}) => new Promise((resolve, reject) => {
            let sort = state.sort.sort;
            if (sort === "name") sort = "object_name_sort";
            if (sort === "price") sort = "price_discount";

            let search_data = {};
            for (let name in state.search_query) {
                if (state.search_query.hasOwnProperty(name) && state.search_query[name]) {
                    if (Array.isArray(state.search_query[name])) {
                        if (state.search_query[name].length) search_data[name] = state.search_query[name]
                    } else {
                        search_data[name] = state.search_query[name]
                    }
                }
            }

            axios.get(`${API_URL}/plans_filter.json?current_query=${JSON.stringify(clear(search_data))}&sort=${sort}&order=${state.sort.order}&per_page=${state.limit.flats}&page=${state.offset.flats}&forcecache=1`)
                .then(res => {
                    /** @namespace layout.price_discount **/
                    /** @namespace layout.area_all **/
                    resolve(res.data.count);
                    if (res.data) {
                        for (let layout of res.data.list) {
                            layout.subways = [];
                            commit("PUSH_FLATS_LIST", layout);
                            if (LAYOUT_OBJECT_CACHE[layout.uri]) {
                                layout.subways = LAYOUT_OBJECT_CACHE[layout.uri].subways;
                                layout.address = LAYOUT_OBJECT_CACHE[layout.uri].address;
                            } else {
                                axios.get(`${API_URL}${layout.uri}?json=1&without_plans=1&forcecache=1`)
                                    .then(res => {
                                        /** @namespace object.build_dates **/
                                        /** @namespace object.photos **/
                                        /** @namespace subway.dist **/
                                        /** @namespace subway.per **/
                                        /** @namespace date_photos **/
                                        layout.subways = res.data.object.subways;
                                        layout.address = res.data.object.address;
                                        LAYOUT_OBJECT_CACHE[layout.uri] = {subways: layout.subways, address: layout.address};
                                    })
                                    .catch(err => reject(err));
                            }
                        }
                    }
                })
                .catch(err => reject(err));
        }),

        PDF_API_REQUEST: ({ commit, dispatch }, { method, data, path, withoutAuth = false }) => new Promise((resolve, reject) => {
            const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` }
            const refresh = new Promise((resolve, reject) => {
                if (withoutAuth) {
                    return resolve()
                }
                const expires = localStorage.getItem('expires')
                if (expires) {
                    const expI = new Date(expires).getTime() - 60000
                    const nowI = new Date().getTime()
                    if (expI <= nowI) {
                        return dispatch('PDF_API_REQUEST', {method: 'POST', path: '/user/refresh-token', withoutAuth: true })
                          .then(({data}) => {
                              if (data.token) {
                                  localStorage.setItem('token', data.token)
                                  localStorage.setItem('expires', data.expires)
                                  return resolve()
                              }
                              commit('SET_AUTH', false)
                              return reject()
                          })
                          .catch(reject)
                    }
                }
                return resolve()
            })
            refresh.then(() => {
                const request = method === 'POST'
                  ? axios.post(`${PDF_URL}${path}`, data, { headers })
                  : axios.get(`${PDF_URL}${path}`, { params: data, headers })
                request.then(resolve).catch(err => {
                    if (err.response && err.response.status === 401) {
                        commit('SET_AUTH', false)
                    } else {
                        reject(err)
                    }
                });
            }).catch(err => {
                if (err.response && err.response.status === 401) {
                    commit('SET_AUTH', false)
                } else {
                    reject(err)
                }
            });
        }),
        GET_CURRENT_USER: ({ commit, dispatch }) => new Promise((resolve, reject) => {
            dispatch('PDF_API_REQUEST', { path: '/user/me' })
              .then(({ data }) => {
                  commit('SET_AUTH', true)
                  commit('SET_USER', data)
              })
              .catch(reject);
        }),
        SIGN_IN: ({ commit, dispatch }, { email, password }) => new Promise((resolve, reject) => {
            commit('SET_AUTH', null)
            dispatch('PDF_API_REQUEST', { method: 'POST', path: '/user/sign-in', data: { email, password }, withoutAuth: true })
              .then(({ data }) => {
                if (data.error) {
                    if (data.code === 'ERR_REQUIRED') {
                        alert('Заполнены не все поля')
                    } else {
                        if (data.code === 'ERR_PERMISSION') {
                            alert('Не верный Email и/или пароль')
                        } else {
                            alert('Ошибка')
                        }
                    }
                }
                if (data.token) {
                    localStorage.setItem('token', data.token)
                    localStorage.setItem('expires', data.expires)
                    return dispatch('GET_CURRENT_USER')
                }
                commit('SET_AUTH', false)
            }).catch(reject);
        }),
        SIGN_OUT: ({ commit, dispatch }) => new Promise((resolve, reject) => {
            dispatch('PDF_API_REQUEST', { path: '/user/sign-out', method: 'POST' }).then(() => {
                localStorage.removeItem('token')
                localStorage.removeItem('expires')
                commit('SET_AUTH', false)
            })
        })
    },
    mutations: {
        SET_SEARCH_QUERY: (state, query) => {
            for (let name in state.search_query) {
                if (state.search_query.hasOwnProperty(name)) {
                    if (query[name]) {
                        if (Array.isArray(state.search_query[name])) {
                            if (Array.isArray(query[name])) {
                                state.search_query[name] = query[name]
                            } else {
                                state.search_query[name] = [query[name]]
                            }
                            state.search_query[name] = state.search_query[name].map(x => {
                                if (`${x}`.replace(/\d/g, "") === "") x = +x;
                                return x
                            })
                        } else {
                            if (typeof state.search_query[name] === "number") {
                                state.search_query[name] = +query[name].replace(/[^\d]/g, "")
                            } else {
                                state.search_query[name] = query[name];
                                if (`${state.search_query[name]}`.replace(/\d/g, "") === "") state.search_query[name] = +state.search_query[name];
                            }
                        }
                    } else {
                        if (Array.isArray(state.search_query[name])) {
                            state.search_query[name] = []
                        } else {
                            if (typeof state.search_query[name] === "number") {
                                state.search_query[name] = 0
                            } else {
                                state.search_query[name] = ""
                            }
                        }
                    }
                }
            }
        },
        ADD_SEARCH_QUERY_ITEM: (state, {name, value}) => {
            if (typeof state.search_query[name] !== "undefined") {
                if (Array.isArray(state.search_query[name])) {
                    if (name === "balcony") {
                        state.search_query[name] = [value];
                    } else {
                        if (state.search_query[name].indexOf(value) < 0) state.search_query[name].push(value);
                    }
                } else {
                    state.search_query[name] = value
                }
            }
        },
        REMOVE_SEARCH_QUERY_ITEM: (state, {name, value}) => {
            if (typeof state.search_query[name] !== "undefined") {
                if (Array.isArray(state.search_query[name])) {
                    if (typeof value !== "undefined") {
                        state.search_query[name] = state.search_query[name].filter(v => v !== value)
                    } else {
                        state.search_query[name] = []
                    }
                } else {
                    let null_value = typeof state.search_query[name] === "number" ? 0 : "";
                    if (typeof value !== "undefined") {
                        state.search_query[name] = state.search_query[name] === value ? null_value : state.search_query[name];
                    } else {
                        state.search_query[name] = null_value
                    }
                }
            }
        },
        CHANGE_SEARCH_QUERY_ITEM: (state, {name, value}) => {
            if (typeof state.search_query[name] !== undefined) state.search_query[name] = value
        },

        OPEN_SEARCH: (state, status) => state.open_search = status,
        PUSH_OBJECTS_LIST: (state, item) => state.list.objects.push(item),
        PUSH_FLATS_LIST: (state, item) => state.list.flats.push(item),
        CLEAR_OBJECTS_LIST: state => state.list.objects = [],
        CLEAR_FLATS_LIST: state => state.list.flats = [],
        NULL_OBJECTS_LIST: state => state.list.objects = false,
        SET_COUNT: (state, count) => state.count = count,
        SET_OFFSET_OBJECTS: (state, value) => state.offset.objects = value,
        SET_OFFSET_FLATS: (state, value) => state.offset.flats = value,
        SET_SORT: (state, name) => {
            state.list.objects = [];
            state.list.flats = [];
            state.offset.objects = 0;
            state.offset.flats = 1;
            if (state.sort.sort === name) {
                state.sort.order = state.sort.order === "asc" ? "desc" : "asc"
            } else {
                state.sort.order = "asc"
            }
            state.sort.sort = name
        },
        SET_404_STATUS: (state, status) => state.page_not_found = status,
        SET_SELECTION_TITLE: (state, title) => state.selection_title = title,

        SET_AUTH: (state, status) => state.auth = status,
        SET_USER: (state, { id, email, admin, person }) => {
            state.user.id = id
            state.user.email = email
            state.user.admin = admin
            state.user.person.firstName = person.firstName
            state.user.person.lastName = person.lastName
            state.user.person.patronymic = person.patronymic
            state.user.person.email = person.email
            state.user.person.phone = person.phone
        }
    }
};
