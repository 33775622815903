var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.state.auth
    ? _c(
        "main",
        [
          _c(
            "header",
            [
              _c("router-link", { staticClass: "logo", attrs: { to: "/" } }),
              _c(
                "a",
                {
                  staticClass: "phone callibri_phone01",
                  attrs: { href: "tel:+78123323332" }
                },
                [_vm._v("+7 (812) 332-33-32")]
              ),
              _c("div", {
                staticClass: "hamburger",
                on: {
                  click: function($event) {
                    _vm.open_menu = !_vm.open_menu
                  }
                }
              })
            ],
            1
          ),
          _vm.open_menu ? _c("main_menu") : _vm._e(),
          _vm.$store.state.page_not_found
            ? _c("div", { staticClass: "page-not-found" }, [
                _vm._v("404 Страница не найдена")
              ])
            : _c("router-view"),
          _c("footer", [
            _c(
              "div",
              { staticClass: "contact" },
              [
                _c("router-link", { staticClass: "logo", attrs: { to: "/" } }),
                _c(
                  "a",
                  {
                    staticClass: "phone callibri_phone01",
                    attrs: { href: "tel:+78123323332" }
                  },
                  [_vm._v("+7 (812) 332-33-32")]
                )
              ],
              1
            ),
            _vm._m(0),
            _c("div", { staticClass: "footer-f" }, [
              _vm._v(
                "© ООО «МИР недвижимости», 2009-" +
                  _vm._s(new Date().getFullYear())
              )
            ])
          ])
        ],
        1
      )
    : _c("auth")
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "social" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("Свяжитесь с нами в социальных сетях")
      ]),
      _c("a", {
        staticClass: "fb social-icon",
        attrs: { href: "https://www.facebook.com/mirndv/", target: "_blank" }
      }),
      _c("a", {
        staticClass: "vk social-icon",
        attrs: { href: "https://vk.com/mirndv", target: "_blank" }
      }),
      _c("a", {
        staticClass: "tw social-icon",
        attrs: { href: "https://twitter.com/mirndv", target: "_blank" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }