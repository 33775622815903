<template lang="pug">
  .auth-screen(:class="{ loading: $store.state.auth === null }")
    form.login-form(v-if="$store.state.auth === false" @submit.prevent="submit")
      input(type="email" name="email" v-model="email" placeholder="Email")
      input(type="password" name="password" v-model="password" placeholder="Пароль")
      button.btn Войти
</template>

<script>
  export default {
    name: 'auth',
    data() {
      return {
        email: '',
        password: '',
      }
    },
    methods: {
      submit() {
        this.$store.dispatch('SIGN_IN', { email: this.email, password: this.password })
      }
    }
  }
</script>

<style lang="stylus" src="../../../stylus/cmp/auth.styl"></style>
