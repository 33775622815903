<template lang="pug">
    main(v-if="$store.state.auth")
        header
            router-link.logo(to="/")
            a.phone.callibri_phone01(href="tel:+78123323332") +7 (812) 332-33-32
            .hamburger(@click="open_menu = !open_menu")
        main_menu(v-if="open_menu")
        .page-not-found(v-if="$store.state.page_not_found") 404 Страница не найдена
        router-view(v-else)
        footer
            .contact
                router-link.logo(to="/")
                a.phone.callibri_phone01(href="tel:+78123323332") +7 (812) 332-33-32
            .social
                .title Свяжитесь с нами в социальных сетях
                a.fb.social-icon(href="https://www.facebook.com/mirndv/" target="_blank")
                a.vk.social-icon(href="https://vk.com/mirndv" target="_blank")
                a.tw.social-icon(href="https://twitter.com/mirndv" target="_blank")
            .footer-f © ООО «МИР недвижимости», 2009-{{(new Date).getFullYear()}}
    auth(v-else)
</template>

<script>
    import main_menu from "./cmp/main_menu.vue"
    import auth from './cmp/auth.vue'

    const data = {
        open_menu: false
    };

    export default {
        name: "App",
        data() {
            return data
        },
        components: {
            auth,
            main_menu
        },
        watch: {
            $route() {
                this.$store.commit("SET_404_STATUS", false);
            }
        },
        mounted() {
          this.$store.dispatch('GET_CURRENT_USER')
        }
    }
</script>
